import { Grid, Box, Typography } from "@mui/material"
import Link from "components/Link"
import Page from "components/Page"

const Links = () => {
  return (
    <Page>
      <Grid container direction="column" alignItems="center" justifyContent="center" padding={2}>
        <Typography variant="h4" padding={0.5}>
          SOCIAL
        </Typography>
        <Link label={"mail"} url={"mailto:contact@pxlq.art"}/>
        <Link label={"warpcast"} url={"https://warpcast.com/pxlq"}/>
        <Link label={"rodeo"} url={"https://rodeo.club/@pxlqart/collection"}/>
        <Link label={"zora"} url={"https://zora.co/@pxlqart/collected"}/>
        <Link label={"twitter"} url={"https://twitter.com/pxlqart"}/>
        <Link label={"ens"} url={"https://app.ens.domains/name/pxlq.eth"}/>
        <Box margin={1}></Box>
        <Typography variant="h4" padding={0.5}>
          GALLERY
        </Typography>
        <Link label={"cyber.pxlq.art"} url={"https://cyber.pxlq.art"}/>
        <Link label={"catalog.ships.pxlq.art"} url={"https://catalog.ships.pxlq.art"}/>
        <Link label={"infinethum.xyz"} url={"https://infinethum.xyz"}/>
        <Box margin={1}></Box>
        <Typography variant="h4" padding={0.5}>
          PLATFORMS
        </Typography>
        <Link label={"open.sea"} url={"https://opensea.io/pxlq"}/>
        <Link label={"art.blocks"} url={"https://www.artblocks.io/user/0xb56ae8a727cf38f1f4716aeda6749d2af340d8f4"}/>
        <Link label={"ethblock.art"} url={"https://ethblock.art/profile/pxlq.eth"}/>
        <Link label={"foundation"} url={"https://foundation.app/@pxlq"}/>
        <Link label={"known.origin"} url={"https://knownorigin.io/pxlq"}/>
        <Link label={"on.cyber"} url={"https://oncyber.io/@0xb56ae8a727cf38f1f4716aeda6749d2af340d8f4"}/>
        <Link label={"aesthetic.2079"} url={"https://oncyber.io/aesthetic2079"}/>
        <Link label={"infinethum.ephemeral"} url={"https://infinethum-ephemeral.glitch.me"}/>
        <Link label={"deca.art"} url={"https://deca.art/pxlqart"}/>
        <Link label={"cyber.cities"} url={"https://oncyber.io/art-blocks?id=14000014"}/>
        <Link label={"space.ships.pxlq.art"} url={"https://space.ships.pxlq.art"}/>
        <Link label={"sothebys.grails"} url={"https://www.sothebys.com/en/buy/auction/2023/grails-part-ii/dynamic-slices-19"}/>
        <Box margin={5}></Box>
      </Grid>
    </Page>
  )
}

export default Links
