import { Box, Link, Typography } from "@mui/material"

let navigation = [
  { 
    text: "home", 
    link: "/"
  },
  {
    text: "links",
    link: "/links"
  },
  {
    text: "generators",
    link: "/generators"
  },
  {
    text: "prints",
    link: "/prints"
  },
  {
    text: "contact",
    link: "mailto:contact@pxlq.art"
  }
]

const Header = () => {
  return (
    <Box display="inline-block" width="100%">
      <Box margin={2}></Box>
      <Box display="flex" marginRight={1} justifyContent={{xs: "center", sm: "right"}}>
        {navigation.map((item, i) => (
          <Link 
            key={i} 
            href={item.link} 
            paddingLeft={0.5}
            paddingRight={0.5}
            marginRight={0.5}
            sx={{
              "textDecoration": "none"
            }}
            >
            <Typography fontFamily="General Sans" fontSize={16}>{item.text}</Typography>
          </Link>
        ))}
      </Box>
    </Box>
  )
}

export default Header