import { Grid, Box, Typography } from "@mui/material"
import Link from "components/Link"
import Page from "components/Page"

const Generators = () => {
  return (
    <Page>
      <Grid container direction="column" alignItems="center" justifyContent="center" padding={2}>
        <Typography fontFamily="General Sans" fontSize={16} fontStyle={"italic"}>            
          work in progress
        </Typography>
        <Typography fontFamily="General Sans" fontSize={16} fontStyle={"italic"}>            
          transferring more sketches over soon...
        </Typography>
        <Box margin={2}></Box>
        <Link label={"infinethum"} url={"https://generator.pxlq.art/infinethum"}/>
        <Link label={"morph"} url={"https://generator.pxlq.art/morph"}/>
        <Link label={"..."}/>
        <Box margin={20}></Box>
      </Grid>
    </Page>
  )
}

export default Generators
