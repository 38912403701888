import { Container, Grid, Box } from "@mui/material"
import Header from "components/Header"
import Footer from "components/Footer"

const Page = ({ children }) => {
  return (
    <>
      <Grid container wrap="wrap">
        <Header/>
        <Grid item xs={12} sm={12} md={12}>
          <Box margin={5}></Box>
          <Container>
            {children}
          </Container>
          <Footer/>
        </Grid>
      </Grid>
    </>
  )
}

export default Page