import { useEffect, useState, useRef } from "react"
import useWindowSize from "hooks/useWindowSize"

interface Props {
  url?: string
}

const Frame = ({ url }: Props) => {
  const [width, setWidth] = useState(0)
  const elementRef = useRef(document.createElement("div"))
  const windowSize = useWindowSize()

  useEffect(() => {
    setWidth(elementRef.current.offsetWidth)
  }, [windowSize.width])

  let size = Math.floor(width)+"px"

  return (
    <div ref={elementRef}>
      <iframe title={url} width={size} height={size} frameBorder="0" scrolling="no" src={url}/>
    </div>
  )
}

export default Frame