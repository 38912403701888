import { Grid, Box, Typography } from "@mui/material"
import Page from "components/Page"

const Links = () => {
  return (
    <Page>
      <Grid container direction="column" alignItems="center" justifyContent="center"> 
        <Box margin={12}></Box>   
        <img src={"/media/lg/z04gf.png"} alt="" width="550px" style={{position: "absolute", zIndex: -1, marginLeft: 300}}></img>
        <Typography fontFamily="General Sans" fontSize={15}>
            Please send any enquiries about
        </Typography>
        <Typography fontFamily="General Sans" fontSize={15}>
            printing or physical works to
            <span>&nbsp;</span>
            <a href="mailto:prints@pxlq.art" target="_blank" rel="noreferrer">prints@pxlq.art</a>
        </Typography>
        <Box margin={10}></Box>
      </Grid>
    </Page>
  )
}

export default Links
